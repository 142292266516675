import { IS_GTAG_ENABLED, IS_XTREMEPUSH_ENABLED } from '@/utils/constants';

export const initSignupTrack = (userId: string, lang: string): void => {
  if (IS_XTREMEPUSH_ENABLED === 'true') {
    const xp_lang = lang === 'esp' ? 'es' : lang;
    window.xtremepush('set', 'user_id', userId, function () {
      window.xtremepush('event', 'complete_registration');
      window.xtremepush('tag', 'registration_success');
      window.xtremepush('tag', 'language', xp_lang);
      window.xtremepush('tag', 'user_lang', xp_lang);
      window.xtremepush('user', 'update', { language: xp_lang });
      window.xtremepush('user', 'update', { user_lang: xp_lang });
    });
  }
};

export const initLoginTrack = (userId: string, lang: string): void => {
  if (IS_XTREMEPUSH_ENABLED === 'true') {
    const xp_lang = lang === 'esp' ? 'es' : lang;
    window.xtremepush('tag', 'login_success');
    window.xtremepush('tag', 'language', xp_lang);
    window.xtremepush('set', 'user_id', userId, function () {
      window.xtremepush('event', 'login');
      window.xtremepush('tag', 'user_lang', xp_lang);
      window.xtremepush('user', 'update', { language: xp_lang });
      window.xtremepush('user', 'update', { user_lang: xp_lang });
    });
  }
};

export function triggerXtremepushEvent(
  eventType: string,
  eventName: string,
  eventData?: string,
): void {
  if (IS_XTREMEPUSH_ENABLED === 'true') {
    if (eventData) {
      window.xtremepush(eventType, eventName, eventData);
    } else {
      window.xtremepush(eventType, eventName);
    }
  }
}

interface Parameters {
  [key: string]: string;
}

export function triggerGTAGEvent(
  eventName: string,
  parameters?: Parameters,
): void {
  if (IS_GTAG_ENABLED === 'true') {
    console.log('%c triggerGTAGEvent:', 'color: yellow; background: green;', {
      event: eventName,
      ...parameters,
    });

    window.dataLayer.push({ event: eventName, ...parameters });
  }
}

export function triggerGTAGInternalNavEvent(link: string) {
  triggerGTAGEvent('pg_navigation_internal_link', { link_clicked: link });
}

export function triggerGTAGExternalNavEvent(link: string) {
  triggerGTAGEvent('pg_navigation_external_link', { link_clicked: link });
}

export function triggerGTAGButtonEvent(text: string) {
  triggerGTAGEvent('button_clicked', { button_text: text });
}

export function triggerGTAGSearchEvent(text: string) {
  triggerGTAGEvent('search_filter_clicked', { search_filter_text: text });
}
