import { FC, PropsWithChildren } from 'react';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { Sidebar } from '@/components/Sidebar';

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Header />
      <Sidebar />

      {children}
      <Footer />
    </>
  );
};

export default MainLayout;
