const ATP_DARK_GREY = '#051224';
const ATP_LIGHT = '#565656';
const ATP_YELLOW = '#E4F53B';
const WHITE = '#FFFFFF';
const BLACK = '#171717';
const RED = '#E22F2F';

const colors = {
  absoluteDark: BLACK,
  defaultGray: '#666666',
  laverCupLihgt: ATP_LIGHT,
  primaryYellow: ATP_YELLOW,

  toast: {
    success: '#009656',
    error: RED,
    text: WHITE,
  },

  appBg: {
    primaryBg: ATP_DARK_GREY,
    secondaryBg: ATP_LIGHT,
  },
  header: {
    logo: WHITE,
    text: WHITE,
    primaryBg: ATP_DARK_GREY,
    mobileBg: '#121FFF',
    secondaryBg: '#A01313',
  },
  footer: {
    text: WHITE,
  },
  cardLarge: {
    bg: WHITE,
    text: RED,
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
  },
  cardSmall: {
    bg: WHITE,
    text: '#009656',
    buttonBg: WHITE,
    buttonBgActive: WHITE,
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
    title: RED,
    description: '#909090',
  },
  tile: {
    bg: WHITE,
    text: RED,
    border: '#D9D9D9',
  },
  timer: {
    text: RED,
    image: '#666666',
    imageActive: RED,
  },
  section: {
    title: RED,
    leftImage: {
      title: RED,
      subtitle: RED,
      description: RED,
    },
  },
  carousel: {
    bullet: 'rgba(0, 0, 0, 0.5)',
    bulletActive: 'rgba(0, 0, 0, 0.9)',
  },
  langSwitcher: {
    bg: '#A01313',
    border: '#A01313',
    text: WHITE,
  },
  input: {
    bg: 'rgba(17, 17, 17, 0.75)',
    text: WHITE,
    placeholder: '#D9D9D9',
    invalid: RED,
    cancel: '#D9D9D9',
    hint: '#D9D9D9',
    success: '#009656',
    eye: '#D9D9D9',
  },
  checkbox: {
    control: '#E4F53B',
    icon: '#262626',
  },
  signup: {
    title: WHITE,
    text: WHITE,
    formBorder: '#e2e8f0',
    checkboxText: WHITE,
  },
  login: {
    bg: '#003366',
    title: WHITE,
    text: WHITE,
  },
  profile: {
    title: ATP_YELLOW,
    text: WHITE,
    formBorder: '#e2e8f0',
  },
  resetPassword: {
    title: WHITE,
    text: WHITE,
    formBorder: '#e2e8f0',
  },
  forgotPassword: {
    title: WHITE,
    text: WHITE,
    formBorder: '#e2e8f0',
    success: '#009656',
  },
  unsubscribe: {
    title: WHITE,
    text: WHITE,
    formBorder: '#e2e8f0',
    iconButton: WHITE,
  },
  countrySelect: {
    bg: 'rgba(17, 17, 17, 0.75)',
    text: WHITE,
    menuBg: ATP_LIGHT,
    placeholder: '#D9D9D9',
    arrow: WHITE,
    invalid: RED,
    menu: ATP_LIGHT,
  },
  sidebar: {
    bg: BLACK,
    close: WHITE,
    logo: WHITE,
    text: WHITE,
  },
  button: {
    defaultText: '#051224',
    primary: {
      bg: '#E4F53B',
    },
    secondary: {
      bg: 'transparent',
      text: '#E4F53B',
      border: '#E4F53B',
    },
  },
};

export default colors;
