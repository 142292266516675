import { Button, Flex, Image, Text, useTheme } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import ATPLogoImg from '@/assets/images/pif-atp-rankings-predictor-line.png';

import { RoutesEnum } from '@/enum/routes.enum';

import { triggerGTAGButtonEvent } from '@/utils/analyticTracks';

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleLogOut = () => {
    triggerGTAGButtonEvent('Log Out');
    localStorage.removeItem('jwt');
    navigate(RoutesEnum.LOGIN);
  };

  return (
    <Flex
      align='center'
      borderTop='2px solid #FFFFFF'
      direction='column'
      gap='16px'
      justify='center'
      p='16px'
    >
      <Flex gap='8px'>
        <Image objectFit='contain' src={ATPLogoImg} w={{ base: '230px' }} />
        <Button color='white' onClick={handleLogOut} variant='outline'>
          Log out
        </Button>
      </Flex>

      <Text
        color={theme.colors.footer.text}
        fontSize='9px'
        fontWeight='500'
        textAlign='center'
      >
        © Copyright 1994 – 2024 ATP Tour, Inc. All Rights Reserved.
        <br />
        No part of this site may be reproduced, stored in a retrieval system or
        transmitted in any way or by any means
        <br />
        (including photocopying, recording or storing it in any medium by
        electronic means), without the written permission of ATP Tour, Inc..
        <br />
        Terms & Conditions Privacy Modern Slavery Statement Feedback
      </Text>
    </Flex>
  );
};

export default Footer;
